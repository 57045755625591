import React from 'react'
import { func, string } from 'prop-types';
import togglerIconLight from '../assets/images/Icon-sun.svg'; 
import togglerIconDark from '../assets/images/Icon-moon.svg';

import './toggler.css';

const Toggle = ({theme,  toggleTheme }) => {
    return (
        // <i onClick={toggleTheme} className={theme === 'light' ? 'far fa-moon' : 'far fa-sun' }>
        // </i>
        <img className="toggler-icon" onClick={toggleTheme} src={theme === 'light' ? togglerIconDark : togglerIconLight} alt="themeToggler icon" />
    );
};
Toggle.propTypes = {
    theme: string.isRequired,
    toggleTheme: func.isRequired,
}
export default Toggle;

