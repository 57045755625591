import React from 'react';

import './Contact.css';

const Contact = () => {
    return (
        <div className="contact">
            hello contact
        </div>
    )
}

export default Contact;