import React from "react";
import { NavLink } from "react-router-dom";
import Toggle from "../themes/toggler";
import { useDarkMode } from "../hooks/useDarkMode";

import "./NavBar.css";

const Navbar = ({ theme, toggleTheme }) => {
  return (
    <ul className="navbar">
      <li className="nav-items">
        <NavLink className="link" to="/about">
          About
        </NavLink>
      </li>
      <li className="nav-items">
        <NavLink className="link" to="/projects">
          Projects
        </NavLink>
      </li>
      {/* <li className="nav-items">
                <NavLink className="link" to="/contact">Contact</NavLink>
            </li> */}
      {/* <li className="nav-items">
                <a  className="link" href="https://drive.google.com/file/d/1Ye2FqQy0gC8haIEFtVGmWwb03VUdsc2f/view?usp=sharing">Resume</a>
            </li> */}
      <li className="nav-items">
        <Toggle className="link" theme={theme} toggleTheme={toggleTheme} />
      </li>
    </ul>
  );
};

export default Navbar;
