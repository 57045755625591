import React from 'react';
import { Link } from 'react-router-dom'

import './Home.css';
import clint_memoji from '../assets/images/ClintMemoji.png'

const Home = () => {
    return (
        <div className="home">
            <div className="container">
                <div className="hero-wrapper">
                <section className="hero-txt">
                <h1>Hi, <br/> I'm Clinton James</h1>
                <p>
                I love blending the art of design and storytelling with the skills of  
                programming<br/> by crafting solid and scalable web apps 
                with engaging user experience<br/> and solving 
                complex problems.
                </p>
                <p>
                I'm a Javascript engineer with values to contribute impact to product and business outcome.
                </p>
                <div className="social-link">
                    <a href="https://www.linkedin.com/in/clinton-james-9ba608b0/"><i class="fab fa-linkedin-in"></i></a>
                    <a href="https://github.com/Clintjeez"><i class="fab fa-github"></i></a>
                    <a href="https://twitter.com/ClintJeezz"><i class="fab fa-twitter"></i></a>
                    <a href="https://www.youtube.com/channel/UC9adhzPM97Stec9paOamVFw"><i class="fab fa-youtube"></i></a>
                </div>
                <a href="mailto:clintjeez@gmail.com"><button className="hero-btn">Let's tell some story <i class="far fa-envelope"></i>
                </button></a>
                
                <div className="hyper-link__wrapper">
                     <Link className="hyper-link" to="/about"><span> 👉 </span></Link>
                </div>
                </section>
                <section className="hero-memoji">
                    <div className="memoji-wrapper">
                        <img className="memoji-img" src={clint_memoji} alt="clinton memoji" />
                    </div>
                </section>
                </div>
            </div>
        </div>
    )
}

export default Home;