import React from 'react';
import { Link } from 'react-router-dom';

import './About.css';
import clintMemoji from '../assets/images/ClintMemoji.png';
import clintImage from '../assets/images/clintPicture.png';

const About = () => {
    return(
        <div className="container">
            <header className="page-header">
                <h3>About me</h3>
                <div className="header-line"></div>
            </header>
            <div className="wrapper">
                <section className="about-txt">
                    <p>I have contributed to impacting business and product outcomes through effective, prioritization, 
                        collaboration and execution of key projects. 
                    </p>
                    <p>I design and develope digital product with strong passion in research, design thinking and product 
                        coordination or product architect.
                        I develope product features with analytical and market data orientation to align with the product goals.
                    </p>
                    <p>
                        I'm skilled in javascript stack and frameworks with some touch of design skills in Adobe XD and some other design tools.
                        And I'm consistently learning new tools and latest updates in building highly performance, optimize and scalable digital products.
                    </p>
                    <p> 
                        I imagine building digital products as a story line which need to be told beautifully.
                    </p>
                    <a href="mailto:clintjeez@gmail.com"><button className="hero-btn">Let's tell some story <i class="far fa-envelope"></i>
                </button></a>
                <div className="hyper-link__wrapper">
                     <Link className="hyper-link" to="/projects"><span> 👉 </span></Link>
                </div>
                </section>
                <section className="about-img">
                    <h1 className="bg-big__txt">ClintJeez</h1>
                    {/* <img className="memoji2-img" src={clintMemoji} alt="clinton memoji" /> */}
                    <img className="clint-img" src={clintImage}  alt="Clinton"/> 
                </section>
            </div>
        </div>
    )
}

export default About;