import React, { useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import {ThemeProvider} from "styled-components";
import { GlobalStyles } from "./themes/globalStyles";
import { lightTheme, darkTheme } from "./themes/theme";
import { useDarkMode } from "./hooks/useDarkMode";


import MainNav from './components/MainNav';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Toggle from './themes/toggler';
import './App.css';



const App = (props) => {
  const [theme, themeToggler] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  
  return (
   <ThemeProvider theme={themeMode}>
     <React.Fragment>
     <GlobalStyles/>
      <Router>
      <MainNav theme={theme} toggleTheme={themeToggler} />
      <Switch>
        <Route path="/" component={Home} exact/>
        <Route path="/about" component={About} />
        <Route path="/projects" component={Projects} />
        <Route path="/contact" component={Contact} />
        <Redirect to="/" />
        </Switch>
      </Router>
      
      </React.Fragment>
      </ThemeProvider>
  );
}

export default App;
