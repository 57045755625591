import React from "react";
import ReactDOM from "react-dom";
import { NavLink } from "react-router-dom";
import Toggle from "../themes/toggler";
import { lightTheme, darkTheme } from "../themes/theme";

import "./SideNav.css";

const SideNav = ({ theme, toggleTheme, closeDrawerHandler }) => {
  const content = (
    <React.Fragment>
      <aside
        className="side-nav"
        style={theme === "light" ? darkTheme : lightTheme}
      >
        <div className="side-nav__top">
          <Toggle
            className="theme-toggle"
            theme={theme}
            toggleTheme={toggleTheme}
          />
          <button className="close-nav__btn" onClick={closeDrawerHandler}>
            <i class="fas fa-times"></i>
          </button>
        </div>
        <ul className="side-navbar">
          <li className="nav-items">
            <NavLink className="link" to="/about" onClick={closeDrawerHandler}>
              About
            </NavLink>
          </li>
          <li className="nav-items">
            <NavLink
              className="link"
              to="/projects"
              onClick={closeDrawerHandler}
            >
              Projects
            </NavLink>
          </li>
          {/* <li className="nav-items">
        <NavLink className="link" to="/contact" onClick={closeDrawerHandler}>Contact</NavLink>
    </li> */}
          {/* <li className="nav-items">
        <a className="link" href="https://drive.google.com/file/d/1Ye2FqQy0gC8haIEFtVGmWwb03VUdsc2f/view?usp=sharing" onClick={closeDrawerHandler}>Resume</a>
    </li> */}
        </ul>
      </aside>
    </React.Fragment>
  );
  return ReactDOM.createPortal(content, document.getElementById("drawer-hook"));
};

export default SideNav;
