import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDarkMode } from "../hooks/useDarkMode";
import { lightTheme, darkTheme } from "../themes/theme";

import Navbar from './NavBar';
import SideNav from './SideNav';
import Backdrop from './Backdrop';

import './MainNav.css';


const MainNav = ({ theme, toggleTheme }) => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const openDrawerHandler = () => {
        setDrawerIsOpen(true);
    };

    const closeDrawerHandler = () =>{
        setDrawerIsOpen(false);
    };

    return(
        <React.Fragment>
            {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
             {drawerIsOpen && (
                 <SideNav className="side-nav__drawer" show={drawerIsOpen} onClick={closeDrawerHandler} theme={theme} toggleTheme={toggleTheme}
                 closeDrawerHandler={closeDrawerHandler } />
             )}   
            
            
        <div className="main-nav">
            <div className="container">
            <nav className="brand">
                <NavLink className="brand-link" to="/" exact>ClintJeez</NavLink>
            </nav>
            <nav className="nav-wrapper">
            <button className="side-nav__btn" onClick={openDrawerHandler}>
            <i class="fas fa-bars"></i>
            </button>
            <Navbar theme={theme} toggleTheme={toggleTheme} />
            </nav>
            </div>
        </div>
        </React.Fragment>
    )
}

export default MainNav;