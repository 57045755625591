import React from "react";

import "./Projects.css";

import project1Img from "../assets/images/tranzact-screen.png";
import project2Img from "../assets/images/advockit-screen.png";
import project3Img from "../assets/images/raedialfarms_screen.png";
import project4Img from "../assets/images/senexpay_screen.png";
import project5Img from "../assets/images/comfy_house2.png";
import project6Img from "../assets/images/netstream.png";
import project7Img from "../assets/images/covid19_track.png";
import project8Img from "../assets/images/Transmonitor.png";

const Project = () => {
  return (
    <div className="projects-page">
      <div className="container">
        <header className="page-header">
          <h3>Projects</h3>
          <div className="header-line"></div>
        </header>
        <section className="project-wrapper">
          <a target="blank" href="https://tranzat.now.sh" className="projects">
            <div className="bg-overlay"></div>
            <img
              className="projects-img"
              src={project1Img}
              alt="web app projects"
            />
            <div className="project-txt">
              <h3>Tranzact</h3>
              <p>Online financial remittance app</p>
            </div>
          </a>
          <a href="/" className="projects">
            <div className="bg-overlay"></div>
            <img
              className="projects-img"
              src={project2Img}
              alt="web app projects"
            />
            <div className="project-txt">
              <h3>Advockit</h3>
              <p>Legal portfolio creator</p>
            </div>
          </a>
          <a
            target="blank"
            href="https://www.raedialfarms.com"
            className="projects"
          >
            <div className="bg-overlay"></div>
            <img
              className="projects-img"
              src={project3Img}
              alt="web app projects"
            />
            <div className="project-txt">
              <h3>Raedialfarms</h3>
              <p>Agro investment platform</p>
            </div>
          </a>
          <a
            target="blank"
            href="https://www.senexpay.com"
            className="projects"
          >
            <div className="bg-overlay"></div>
            <img
              className="projects-img"
              src={project4Img}
              alt="web app projects"
            />
            <div className="project-txt">
              <h3>Senexpay</h3>
              <p>Bitcoin buying and selling app</p>
            </div>
          </a>
          <a
            target="blank"
            href="https://comfy-house-lilac.vercel.app"
            className="projects"
          >
            <div className="bg-overlay"></div>
            <img
              className="projects-img"
              src={project5Img}
              alt="web app projects"
            />
            <div className="project-txt">
              <h3>Comfy House</h3>
              <p>Furniture ecommerce</p>
            </div>
          </a>
          <a
            target="blank"
            href="https://netstream-lac.vercel.app"
            className="projects"
          >
            <div className="bg-overlay"></div>
            <img
              className="projects-img"
              src={project6Img}
              alt="web app projects"
            />
            <div className="project-txt">
              <h3>Netstream</h3>
              <p>Netflix clone</p>
            </div>
          </a>
          <a
            target="blank"
            href="https://covid-19-tracker-lake.vercel.app"
            className="projects"
          >
            <div className="bg-overlay"></div>
            <img
              className="projects-img"
              src={project7Img}
              alt="web app projects"
            />
            <div className="project-txt">
              <h3>Covid19 Tracker</h3>
              <p>A simple covid19 tracker</p>
            </div>
          </a>
          <a
            target="blank"
            href="https://transmonitor.vercel.app"
            className="projects"
          >
            <div className="bg-overlay"></div>
            <img
              className="projects-img"
              src={project8Img}
              alt="web app projects"
            />
            <div className="project-txt">
              <h3>Transmonitor</h3>
              <p>A fintech app user dashboard</p>
            </div>
          </a>
        </section>
      </div>
    </div>
  );
};

export default Project;
